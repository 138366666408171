import logo from './atlas-logo.png';
import './App.css';

import {useState} from "react";
import {db} from "./firebase-config";
//import {analytics} from "./firebase-config-analytics";
import {getAnalytics, logEvent} from "firebase/analytics";
import {collection, addDoc} from "firebase/firestore";

function App() {
  const [newEmailAddress, setNewEmailAddress]   = useState("");
  const messagesCollectionRef                   = collection(db, "messages");
  const [status, setStatus]                     = useState(null);
  //const analyze = analytics();
  const analyze = getAnalytics();

  var singleView = false;
  if (!singleView) {
    logEvent(analyze, 'app_view');
    singleView = true;
  }

  const createMessage = async (e) => {
    e.preventDefault();
    setStatus('Thank you for signing up!');
    logEvent(analyze, 'app_signup');
    await addDoc(messagesCollectionRef, {emailAddress: newEmailAddress, messageBody: 'Sign me up'});
  };

  const navChatGPT = () => {
    logEvent(analyze, 'app_opened_atlas_gpt');
    window.open("https://chat.openai.com/g/g-t9QnDyUEC-atlas", '_blank');
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Atlas
        </p>
        <div>Helping you navigate new cities.</div>
        <p>Our new custom GPT, an advanced guide to help you navigate your new and exciting adventures.</p>
        {/*

        <a className="App-link" onClick={() => navChatGPT()} href="" target="_blank" rel="noopener noreferrer" >
          Click here to use Atlas in ChatGPT now!
        </a
        */}
        <button className="button-gpt" onClick={() => navChatGPT()}>Click here to use Atlas in ChatGPT now!</button>
        <p>
          Or
        </p>

        {!status ? 
            <form onSubmit={createMessage}>
              <input required className="input-email" type='email' label="Sign Up" placeholder="Email" value={newEmailAddress} onChange={(e) => setNewEmailAddress(e.target.value)}></input>
              <button className="button-signup" type="submit">Sign Up for Updates!</button>
            </form>
          : <div>{status}</div>
        }

      </header>
    </div>
  );
}

export default App;
